@media only screen and (max-width: 800px) {
    .footer-info {
        width: 60%;
        margin: 0 auto;
        justify-content: space-between;
        text-align: center;
        flex-direction: column !important;
    }
    .footer-info .info-element{
        margin: 16px auto;

    }
    .footer-info .info-element a {
        text-decoration: none;
        color: #1A1A1A;
        font-weight: 600;
    }

    .footer-info .info-element i {
        color: #A9A9A9;
        margin-right: 8px;
        float: left;
    }

    .footer-social {
        margin: 32px auto 96px auto;
        width: 60%;
        justify-content: space-around;
    }
}