@import url('https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@300;400;500;700;900&display=swap');

header {
    box-shadow: 0px 2px 9px 3px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 2px 9px 3px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 2px 9px 3px rgba(0, 0, 0, 0.25);
    position: fixed;
    z-index: 10;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #FFFFFF;
}
.header-switch, header .fa-xmark{
    display: none;
}
.header-inner {
    padding: 16px 48px;
    display: flex;
}

.header-logo {
    text-decoration: none !important;
    display: flex;
    height: 80%;
    margin: auto 0;
    margin-right: 64px;
}

.header-logo p {
    padding: 0;
    margin: auto 8px;
}

.header-logo img {
    height: 100%;
}

.header-nav {
    display: flex;
    text-align: center;
}

.header-nav .nav-el {
    margin: auto;
}
.header-nav .active a{
    color: #D2A704;
}
.header-nav a {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    text-decoration: none;
    margin: auto 16px;
    padding: 0;
}

.lang {
    margin-left: auto;
}

.lang-box {
    display: flex;
    border: 1px solid #F7F7F7;
    border-radius: 1.275e+06px;
    height: 32px;
    width: 72px;
    padding: 4px 8px;
    justify-content: space-between;
}

.lang-box img {
    border-radius: 50%;
    border: 1px solid #F7F7F7;
    width: 32px;
    height: 32px;
    margin: auto 0;
}

.lang-box i {
    margin: auto 0;
}

.lang-box p {
    margin: auto 0;
    font-weight: 700;
}

header .header-logo p {
    font-family: "Zen Maru Gothic";
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 33px;
    color: #929292;
}
.dropdown{
    position: absolute;
}
.dropdown .lang-box{
    background-color: #FFFFFF;

    margin: 8px 0;
}