@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.flex {
    display: flex;
}

.background {
    padding: 0;
    margin: 0;
    top: 256px;
    left: 0;
    width: 100vw;
    max-width: 100%;
    position: absolute;
    z-index: 1 !important;
}

.background img {
    padding: 0;
    margin: 0;
    width: 100%;
    height: auto;
    position: relative;
    z-index: -10 !important;
}

.p-n {
    padding: 64px 96px;
}

.landing {
    padding: 64px 0;
    z-index: 2 !important;
}

.z1 {
    z-index: -10 !important;
}

.z2 {
    z-index: 2 !important;
}

.e-2 .text-section {
    width: 55%;
}

.e-2 .svg-section {
    width: 40%;
}

.e-2 .svg-section img {
    margin: auto;
}

.e-2 .text-section h1 {
    font-size: 64px;
    margin-bottom: 0px;
}

.e-2 .text-section h2 {
    margin: 0;
    font-size: 32px;
}

.e-2 .text-section p {
    font-size: 16px;
    width: 75%;
}

.e-2 .flex {
    margin-top: 32px;
}

.e-2 .flex a {
    color: #000000;
    font-size: 20px;
    text-decoration: none;
    margin-right: 64px;
    font-weight: 600;
}


.hexagon {
    padding: 10px;
    background: #F7BC3C;
    position: relative;
    left: 25px;
    box-sizing: border-box;
    height: 48px;
}

.hexagon::before,
.hexagon::after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    top: 0;
    /* half height */
    border-top: 24px solid transparent;
    border-bottom: 24px solid transparent;
}

.hexagon::before {
    left: -12px;
    border-right: 12px solid #F7BC3C;
}

.hexagon::after {
    right: -12px;
    border-left: 12px solid #F7BC3C;
}

.hexagon-1::after,
.hexagon-1::before,
.hexagon-1 {
    background: transparent;
    border: transparent;
}

.hexagon-1 {
    color: #A97A13 !important;

}

.hexagon-1 {
    border-top: 2px solid #F7BC3C;
    border-bottom: 2px solid #F7BC3C;
    position: relative;
    text-align: center;
    color: #F7BC3C;
}

.hexagon-1:before,
.hexagon-1:after {
    content: '';
    position: absolute;
    top: 8px;
    height: 25px;
    width: 25px;
    border: 2px solid #F7BC3C;
    -webkit-transform: scale(0.8, 1.25) rotate(45deg);
    -moz-transform: scale(0.8, 1.25) rotate(45deg);
    -ms-transform: scale(0.8, 1.25) rotate(45deg);
    transform: scale(0.8, 1.25) rotate(45deg);
}

.hexagon-1:before {
    left: -14px;
    border-top: 0px solid transparent;
    border-right: 0px solid transparent;
}

.hexagon-1:after {
    right: -14px;
    border-bottom: 0px solid transparent;
    border-left: 0px solid transparent;
}

.hexagon-1:hover,
.hexagon-1:hover:before,
.hexagon-1:hover:after {
    background-color: #F7BC3C;
    color: #EEE;
}

.card-header {
    text-align: center;
    width: fit-content;
    margin: 0 auto;
    font-size: 32px;
}

.card-header h2 {
    margin-bottom: 0;
    padding-bottom: 0;
}

.card-header h3 {
    padding-top: 0;
    font-size: 24px;
    margin-bottom: 96px;
}

.card-renderer {
    display: flex;
    padding: 0 10vw;
    justify-content: space-around;
}

.card-renderer .card {
    position: relative;
    width: 30%;
    box-shadow: 0px 0px 21px -12px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 0px 21px -12px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 21px -12px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    background-color: #FFFFFF;
}

.card-renderer .inner-card {
    position: relative;
    overflow: hidden;
    padding: 32px;
}

.card-renderer .inner-card .card-img {
    width: 192px;
    height: 192px;
    margin: 0 auto;
}

.card-renderer .inner-card .card-img img {
    width: 100%;
}

.card h3 {
    color: #DB8642;
    font-size: 24px;
}

.boxes {
    background-color: #F6EEE8;
    padding: 36px 10vw;
    margin: 32px 0;
}

.boxes h1 {
    font-size: 48px;
}


.box-renderer {
    width: 70%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 36px 24px;
}

.box-svg {
    width: 30%;
    padding: 0 32px;
}

.box-svg img {
    width: 100%;
    height: auto;
}

.box-text {
    width: 75%;
}

.boximg {
    width: 25%;
}

.box {
    background: #FBFBFB;
    padding: 32px 16px;
    height: 96px;
}

.box h5,
.box p {
    padding: 0;
    margin: 0;
}

.box h5 {
    font-size: 20px;
    margin-bottom: 8px;
}

.box p {
    font-size: 12px;
}

.box:nth-child(6) p {
    font-size: 11.3px;
}

.statistics {
    padding: 24px 10vw;
    position: relative;
}

.statistics h1 {
    text-align: center;
    font-size: 48px;
}

.statistics .sfimg {
    padding: 0 5%;
    width: 50%;
}

.statistics .statistics-text h2 {
    font-size: 40px;
}

.statistics .statistics-text p {
    font-size: 20px;
    width: 75%;
}

.bg-hex {
    position: absolute;
    top: 0;
    left: -20vw;
    z-index: -10 !important;
    max-width: 100%;
}

.bg-hex-r {
    top: 64px;
    left: 50vw;
}

.howtobee {
    margin-bottom: 64px;
}

.stat-box {
    width: 18%;
    margin: auto;
    box-shadow: 0px 0px 21px -12px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 0px 21px -12px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 21px -12px rgba(0, 0, 0, 0.5);
    height: 300px;
    padding: 24px;
}

.align-right {
    display: flex;
    flex-direction: column;
    justify-content: right;
}

.align-right img {
    height: 64px;
    margin-left: auto;
}

.stat-box h1 {
    color: #313131;
    margin: 8px 0;
    float: none;
    font-size: 96px;
    text-align: center;
}

.stat-box .align-left h4 {
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 16px;
}

.stat-box .align-left h6 {
    font-size: 14px;
    margin-top: 4px;
}

.process {
    background: #FBFBFB;
    padding: 32px 10vw;
}

.process h2,
.process h1 {
    text-align: center;
}

.process h2 {
    font-size: 24px;
}

.process h1 {
    font-size: 64px;
    margin-top: 0;
}

.stair-boxes {
    display: flex;
    padding-bottom: 96px;
}

.stair-box {
    min-height: 432px;
    background: #f5f5f5;
    width: 20%;
    margin: auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    text-align: center;
    box-shadow: 0px 0px 21px -12px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 0px 21px -12px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 21px -12px rgba(0, 0, 0, 0.5);
    border-radius: 24px;
}

.stair-box:nth-child(even) {
    transform: translateY(32px);
}

.stair-box h3 {
    margin: 16px 0;
    font-size: 32px;
    margin-top: 0;
}

.stair-box img {
    width: 70%;
    margin: 0 auto;
}

.stair-box h4 {
    margin: 16px 0;
    font-size: 32px;
}

.stair-box p {
    margin: 0 auto;
    width: 75%;
}

.blog {
    overflow: hidden;
    padding: 64px 12vw;
    position: relative;
}

.blog h1 {
    text-align: center;
    font-size: 48px;
    color: #191919;
}

.blog .bg-square {
    top: 0;
    left: -5vw;
    position: absolute;
    width: 110vw;
    overflow: hidden;
    z-index: -10;
}

.blog .bg-square img {
    width: 100%;
    z-index: -10;

}

.slidercomponent {
    position: relative;
    margin-bottom: 64px;
}

.slider-arrow {
    position: absolute;
    width: 64px;
    height: auto;
    top: 192px;
    cursor: pointer;
}

.slider-arrow-left {
    left: -96px;
}

.slider-arrow-right {
    right: -24px;
}

.contact h1 {
    width: 20%;
    text-align: center;
    margin: 0 auto;
}

.contact h2 {
    margin: 0;
    text-align: center;
}

.contact-form {
    display: flex;
    flex-direction: column;
    width: 35%;
    margin: 32px auto;
}

.contact-form input,
.contact-form textarea,
.contact-form button {
    margin: 8px;
    padding: 16px;
    border: none;
    outline: none;
    font-family: "Quicksand";
    border-radius: 16px;
}

.contact-form input,
.contact-form textarea {
    background: #F8F8F8;
}

.contact-form button {
    color: black;
    font-size: 20px;
    font-weight: 800;
    cursor: pointer;
}
.contact-form .button-disabled{
    opacity: 50%;
}
.card-bg{
    position: absolute;
    width: 100%;
    height: auto;
}
.contact-form .error-box{
    padding: 8px;
    background-color: red;
    border-radius: 16px;
    color: #FFFFFF;
    text-align: center;
}