.post-page{
    padding: 64px 5vw;
    text-align: center;
}
.post-main{
    padding: 32px 5vw;

}
.post-main a{
    font-size: 20px;
    text-decoration: none;
}
.post-main img{
    width: 90%;
    margin: 16px auto;
}
.post-author{
    height: 96px;
    width: 25%;
}
.post-author img{
    width: 64px;
    height: 64px;
    clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);

}
.authorText{
    margin: auto 0;
}
.post-author h4, .post-author h5{
    margin: 0;
    text-align: left;
}
.post-author h5{
    color: #9897A3;

}
.post-other h3{
    font-size: 32px;
}
.post-other{
    width: 90%;
    margin: auto;
}
