@media only screen and (max-width: 800px) {
    .background {
        top: 75vh;
        left: -125vw;
        width: 250vw;
        position: absolute;
        z-index: 1 !important;
    }

    .background img {
        width: 100%;
        height: auto;
        z-index: 1 !important;
    }


    .mflex {
        display: block !important;
    }

    .p-n {
        padding: 32px 16px;
    }

    .e-2 .svg-section img {
        width: 100%;
        transform: translateY(-96px);
        position: relative;
        z-index: 1;
    }

    .e-2 .text-section {
        width: 100%;
        position: relative;
        z-index: 2;
    }

    .e-2 .svg-section {
        width: 100%;
    }

    .box-svg img {
        width: 100%;
    }

    .howtobee img {
        width: 100%;
    }

    .e-2 .text-section h1 {
        margin-top: 0;
        font-size: 64px;
    }

    .e-2 .text-section h2 {
        margin: 0;
        font-size: 28px;
    }

    .e-2 .text-section p {
        font-size: 20px;
        width: 100%;
    }

    .e-2 .flex a {
        font-size: 14px;
    }

    .hexagon {
        height: 40px;
    }

    .hexagon::before,
    .hexagon::after {
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
    }

    .hexagon-1:before,
    .hexagon-1:after {
        content: '';
        position: absolute;
        top: 7px;
        height: 21px;
        width: 21px;
        border: 2px solid #F7BC3C;
    }

    .hexagon-1:before {
        left: -12px;
        border-top: 0px solid transparent;
        border-right: 0px solid transparent;
    }

    .hexagon-1:after {
        right: -12px;
        border-bottom: 0px solid transparent;
        border-left: 0px solid transparent;
    }

    .cards {
        pad: 0 16px;
        text-align: center;
    }

    .card-renderer {
        display: block;
    }

    .card-renderer .card {
        width: 100%;
        margin: 32px 0;
    }

    .box-renderer {
        width: 100%;
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        padding: 0 !important;
        margin: 0 !important;
    }

    .box {
        margin: 16px auto;
        height: 144px;
        width: 90%;
    }

    .box-svg {
        width: 100%;
        padding: 0 32px;
        margin-top: 64px;
    }

    .box-svg img {
        width: 100%;
        height: auto;
    }

    .box-text {
        width: 75%;
    }

    .boximg {
        width: 25%;
    }

    .box h5,
    .box p {
        padding: 0;
        margin: 0;
    }

    .stat-boxes {
        display: block;
    }

    .stat-box {
        width: 80%;
        margin: 32px 0;
    }

    .bg-hex {
        overflow: hidden;
        max-width: 70vw !important;
        display: none;
    }

    .process h1 {
        font-size: 32px;
    }

    .stair-box {
        width: 90%;
        transform: translateY(0) !important;
        margin: 32px 0;
    }

    .blog {
        padding: 64px 16px;
    }

    .landing .blog-item {
        transform: translateX(5%);
        width: 90% !important;
    }

    .slider-arrow {
        display: none;
    }

    .box-svg {
        display: none;
    }

    .contact h1 {
        width: 80%;
        text-align: center;
        margin: 0 auto;
    }

    .contact h2 {
        margin: 0;
        text-align: center;
    }

    .contact-form {
        display: flex;
        flex-direction: column;
        width: 70%;
        margin: 32px auto;
    }
    .e-2 .text-section h1 {
        font-size: 56px;
        margin-bottom: 0px;
        height: 300px;
        width: 80vw !important;
    }
    
}