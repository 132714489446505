
.blog-item {
    width: 85% !important;
    background: #f5f5f5;
    box-shadow: 0px 0px 21px -12px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 0px 21px -12px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 21px -12px rgba(0, 0, 0, 0.5);
    transform: translateX(5%);

}

.blog-profile {
    padding: 16px;
}

.blog-profile p {
    margin-left: 16px;

}
.blog-profile img{
    width: 64px;
    border-radius: 100%;

}
.blog-item .blog-img{
    width: 100%;
}
.blog-item .blog-img img {
    width: 100%;
}

.blog-item .blog-title {
    padding: 0 16px;
}

.blog-item .blog-title h3 {
    margin: 0;
    font-size: 24px;
}

.blog-item .blog-continue {
    padding: 16px;
}

.blog-item .blog-continue a {
    text-decoration: none;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    margin-left: auto;
    transform: translateX(-32px);
}
.blog-item .hexagon{
    background-color: #DB8642;
}
.blog-item .hexagon::before {
    left: -12px;
    border-right: 12px solid #DB8642;
}

.blog-item .hexagon::after {
    right: -12px;
    border-left: 12px solid #DB8642;
}