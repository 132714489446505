.apply-page{
    padding: 64px 0;
    text-align: center;
}
.apply-page h2{
    padding-top: 96px;
    margin: 0;
}
.apply-page h1{
    font-size: 64px;
    margin: 0;
}
.bg-apply{
    overflow: hidden;
    width: 100vw;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
}
.apply-page .flex .fdiv{
    width: 50%;
}
.apply-page .flex-form{
    width: 90%;
}
.apply-page .flex .flex-img{
    width: 50%;
    height: auto;
}
.apply-page .flex .flex-img img{
    width: 100%;
}