@media only screen and (max-width: 800px) {
    .blog-renderer {
        display: block !important;
    }
    .blog-renderer .blog-item{
        padding: 0;
        margin: 32px 0 !important;
        width: 90% !important;
    
    }
    .page-select{
        width: 90%;
    }
}