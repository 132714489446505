@media only screen and (max-width: 800px) {
    .about-page {
        padding: 96px 2vw;
        text-align: center;
    }

    .about-page h2 {
        margin-bottom: 0;
    }

    .about-page h1 {
        margin: 0;
        font-size: 48px;
    }

    .about-page h4 {
        font-size: 16px;
        width: 70%;
        margin: 16px auto;
    }

    .about-page img {
        width: 80%;
    }

    .about-page h3 {
        font-size: 24px;
    }

    .about-page p {
        width: 80%;
        margin: 16px auto;
    }

}