.blog-page {
    padding: 64px 10vw;
}

.blog-page h1 {
    text-align: center;
    font-size: 64px;
}

.blog-renderer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 48px 0;
    grid-template-areas:
        ". . ."
        ". . ."
        ". . ."
        ". . .";
}

.page-select {
    display: flex;
    width: 40%;
    margin: 32px auto;
    justify-content: space-around;
}

.blog-hexagon {
    position: relative;
    padding: 1px;
    clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
    background: #EBEBEB;
}

.select-hexagon {
    cursor: pointer;
    width: 64px;
    height: 72px;
    clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
    color: #FFFFFF;
    background: #FFFFFF;
}
.select-hexagon *{
    cursor: pointer;
}

.blog-hexagon p{
    cursor: pointer;
    position: absolute;
    top: -4px;
    left: 20px;
    width: 24px;
    text-align: center;
    color: #000000;
    font-weight: 600;
    font-size: 24px;
}
.page-select i{
    transform: translateY(18px);
}


.active-hex{
    background: transparent;
}
.active-hex .select-hexagon{
    background: #DB8642;
}
.active-hex p{
    color: #FFFFFF;
}
.page-arrow{
    width: 64px;
    height: auto;
}