@media only screen and (min-width: 1800px) {
    .p-n {
        padding: 64px 192px;
    }
    
    .e-2 .text-section h1 {
        font-size: 96px;
    }

    .e-2 .text-section h2 {
        font-size: 48px;
    }

    .e-2 .text-section p {
        font-size: 24px;
    }    
}