@media only screen and (max-width: 800px) {
    .header-switch {
        display: block;
    }

    .header-nav {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #F7F7F7;
        width: 100vw;
        height: 100vh;
        transform: translateY(-100vh);
        transition: all 0.5s ease;
    }

    .active-nav {
        transform: translateY(0vh);
        transition: all 0.5s ease;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .active-nav .fa-xmark {
        display: block;
    }

    .header-nav a {
        font-size: 32px;
    }

    .header-nav .nav-el {
        margin: 16px 0;
    }

    .lang {
        display: none;
    }

    .header-switch {
        margin-left: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .header-switch i {
        text-align: center;
        margin: auto;
        color: #929292;
    }

    .header-inner {
        padding: 16px 16px;
    }
}