.apply-form {
    margin: 32px auto;
    min-width: 100px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
}

.apply-form input,
.apply-form textarea,
.apply-form button {
    margin: 8px;
    padding: 16px;
    border: none;
    outline: none;
    font-family: "Quicksand";
    border-radius: 16px;
}

.apply-form input,
.apply-form textarea {
    background: #F8F8F8;
}

.apply-form label {
    margin: 32px 0;
    font-size: 12px;
}
.apply-form .filebox{
    max-width: 100%;
    border: #F3F3F3 solid 1px;
    border-radius: 16px;
    padding: 32px 16px;
    margin: 8px;
}
.flex-a,
.flex-b {
    display: flex;
}
.flex-a .flex-b{
    margin-right: auto;
}
.flex-a .file-input{
    margin: 0;
    width: 25%;
    margin-left: auto;
    background-color: #F7BC3C;
    color: #000000;
    font-size: 24px;
    font-weight: 700;
    border-radius: 24px;
    padding: 8px;
    overflow: hidden;
}
.flex-a input{
   display: none;
}
.apply-form i {
    color: #A9A9A9;
}
.filetext{
    margin-left: 8px;
}
.filetext p{
    text-align: left;
    margin: 0;
}
.filetext p:nth-child(2){
    color: #A9A9A9;
}
.errorbox{
    color: red;
}
.apply-form button{
    cursor: pointer;
}
.apply-form .error-box{
    background-color: red;
    padding: 8px;
    color: #FFFFFF;
    border-radius: 16px;
}
.apply-form .button-disabled{
    opacity: 80%;
}