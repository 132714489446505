footer {
    background: #FBFBFB;
}
.footer-bottom{
    background: #F5F5F5;
}
.footer-logo{
    width: fit-content;
    margin: 32px auto;
    display: flex;
    padding-top: 96px;
}
.footer-logo img{
    fill: #A9A9A9;
}
.footer-logo p{
    margin-left: 8px;
    color: #A9A9A9;
    font-size: 24px;
    font-weight: 700;
}
.footer-info{
    width: 50%;
    margin: 0 auto;
    justify-content: space-between;
}
.footer-info .info-element a{
    text-decoration: none;
    color: #1A1A1A;
    font-weight: 600;
}
.footer-info .info-element i{
    color: #A9A9A9;
    margin-right: 8px;
    float: left;
}
.footer-social{
    margin: 32px auto 96px auto;
    width: 40%;
    justify-content: space-around;
}
.footer-social a{
    color: #A9A9A9;
}
.footer-lines div{
    width: 50%;
    height: 8px;
}
.footer-bottom{
    text-align: center;
}
.footer-bottom p{
    padding-top: 16px;
}
.footer-lines div:nth-child(1) {
    background: #DB8642;
}
.footer-lines div:nth-child(2) {
    background: #F7BC3C;
}