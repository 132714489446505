@media only screen and (min-width: 3000px) {
    .p-n {
        padding: 64px 256px;
    }
    
    .e-2 .text-section h1 {
        font-size: 128px;
    }

    .e-2 .text-section h2 {
        font-size: 64px;
    }

    .e-2 .text-section p {
        font-size: 36px;
    }


    .e-2 .flex a {
        color: #000000;
        font-size: 40px;
        text-decoration: none;
        margin-right: 128px;
        font-weight: 600;
    }

    .hexagon {
        padding: 10px;
        background: #F7BC3C;
        position: relative;
        left: 25px;
        box-sizing: border-box;
        height: 80px;
    }

    .hexagon::before,
    .hexagon::after {
        content: "";
        position: absolute;
        height: 0;
        width: 0;
        top: 0;
        /* half height */
        border-top: 40px solid transparent;
        border-bottom: 40px solid transparent;
    }

    .hexagon::before {
        left: -24px;
        border-right: 24px solid #F7BC3C;
    }

    .hexagon::after {
        right: -24px;
        border-left: 24px solid #F7BC3C;
    }

    .hexagon-1::after,
    .hexagon-1::before,
    .hexagon-1 {
        background: transparent;
        border: transparent;
    }

    .hexagon-1 {
        color: #A97A13 !important;

    }

    .hexagon-1 {
        border-top: 2px solid #F7BC3C;
        border-bottom: 2px solid #F7BC3C;
        position: relative;
        text-align: center;
        color: #F7BC3C;
    }

    .hexagon-1:before,
    .hexagon-1:after {
        content: '';
        position: absolute;
        top: 14px;
        height: 43px;
        width: 43px;
        border: 2px solid #F7BC3C;
        -webkit-transform: scale(0.8, 1.25) rotate(45deg);
        -moz-transform: scale(0.8, 1.25) rotate(45deg);
        -ms-transform: scale(0.8, 1.25) rotate(45deg);
        transform: scale(0.8, 1.25) rotate(45deg);
    }

    .hexagon-1:before {
        left: -14px;
        border-top: 0px solid transparent;
        border-right: 0px solid transparent;
    }

    .hexagon-1:after {
        right: -14px;
        border-bottom: 0px solid transparent;
        border-left: 0px solid transparent;
    }

    .hexagon-1:hover,
    .hexagon-1:hover:before,
    .hexagon-1:hover:after {
        background-color: #F7BC3C;
        color: #EEE;
    }

}