.about-page{
    padding: 96px 10vw;
    text-align: center;
}
.about-page h2{
    margin-bottom: 0;
}
.about-page h1{
    margin: 0;
    font-size: 64px;
}
.about-page h4{
    font-size: 24px;
    width: 50%;
    margin: 16px auto;
}
.about-page img{
    width: 60%;
}
.about-page h3{
    font-size: 24px;
}
.about-page p{
    width: 50%;
    margin: 16px auto;
}
.bg-about{
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: 100%;
    z-index: -10;
}