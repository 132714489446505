@media only screen and (max-width: 800px) {
    .apply-page {
        padding: 32px 5vw;
    }

    .apply-page h1 {
        font-size: 48px;
    }

    .apply-page .flex .fdiv {
        width: 100%;
    }

    .apply-page .flex-form {
        width: 80%;
    }

    .apply-page .flex .flex-img {
        display: none;
    }
    .apply-page .flex-a .file-input {
        width: 50%;
        height: fit-content;
        margin: auto 0;
        font-size: 16px;
    }
     
}